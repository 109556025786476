body {
    margin: 0 auto;
    padding: 0;
    background-color: #fff5fa;
  }
  img{margin-top: 10%;}
  #login .container #login-row #login-column #login-box {
    margin-top: 18%;
    width: 100%;
    padding: 45px;
    height: auto;
    overflow: hidden;
    border-radius:6px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 6px 15px 0px rgba(41,41,41,.2);
  -moz-box-shadow: 0px 6px 15px 0px rgba(41,41,41,.2);
  box-shadow: 0px 6px 15px 0px rgba(41,41,41,.2);
  }
  .text-info{color: #181423 !important;}